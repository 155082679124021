import React, { useEffect, useState } from "react";
import "./index.scss";
import CopilotIcon from "./Assets/CopilotIcon.png";
import ChatContainer from "./SideBar/ChatContainer";
import { TooltipHost } from "@fluentui/react";

function Sidebar({
  workspaceid,
  opensidebar,
  setOpenSidebar,
}: {
  workspaceid: number;
  opensidebar?: boolean;
  setOpenSidebar?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isSessionEnded, setSessionEnded] = useState(false);
  const [sideBarWidth, setSideBarWidth] = useState(200);
  const [sideIconWidth, setSideIconWidth] = useState(35);
  const [sideIconMargin, setSideIconMargin] = useState(9);
  const [sideIconHeight, setSideIconHeight] = useState(
    document.body.clientWidth <= 1764 ? "54px" : "100vh"
  );

  useEffect(() => {
    if (opensidebar) {
      setSidebarOpen(true);
    }
  }, [opensidebar]);
  /**
   * Handles the minimize click event.
   */
  const handleMinimizeClick = () => {
    setSidebarOpen(false);
    if (setOpenSidebar) {
      setOpenSidebar(false);
    }
  };

  /**
   * Handles the button click event.
   */
  const handleButtonClick = () => {
    if (isSessionEnded) {
      setSessionEnded(false); // Start a new session
      setSidebarOpen(true); // Open the sidebar when starting a new session
    } else {
      setSidebarOpen(true); // Toggle sidebar visibility
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      const width = 200;
      const iconWidth = 35;
      let ratio = window.devicePixelRatio;
      let sideBarWidth = width / ratio;
      setSideBarWidth(sideBarWidth);
      let sideIconWidth = iconWidth / ratio;
      setSideIconWidth(sideIconWidth);

      let innerWidth = document.body.clientWidth;
      if (innerWidth <= 1764) {
        setSideIconHeight(54 / ratio + "px");
      } else {
        setSideIconHeight("100vh");
      }

      setSideIconMargin(9 / ratio);
    });
  }, []);

  //Minimizing the sidebar after 1 seconds when the component mounts

  useEffect(() => {
    const timer = setTimeout(() => {
      setSidebarOpen(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className="sidebar"
      style={{
        width: isSidebarOpen ? sideBarWidth : 54,
        maxWidth: 200,
        height: sideIconHeight,
      }}
    >
      <button className="chat-button" onClick={handleButtonClick}>
        <TooltipHost content="PMM Copilot" id="chatIconId">
          <img
            src={CopilotIcon}
            alt="Chat"
            style={{
              width: sideIconWidth,
              maxWidth: 35,
              height: sideIconWidth,
              maxHeight: 35,
              marginTop: sideIconMargin,
            }}
            className="chat-icon"
          />
        </TooltipHost>
      </button>
      {!isSessionEnded && (
        <ChatContainer
          handleMinimizeClick={handleMinimizeClick}
          workspaceid={workspaceid}
          isVisible={isSidebarOpen}
        />
      )}
    </div>
  );
}

export default Sidebar;
