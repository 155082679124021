import { useMemo, useEffect } from "react";
import moment from "moment";
import striptags from "striptags";
import "../../Shared/css/timelineGrid.scss";
import "./index.scss";
import { useSelector } from "react-redux";
import { useUrlQueryParam } from "../../../utils/url";
import { splitStringToArray } from "../../../utils/string";
import { RootState, useAppDispatch } from "../../../store";
import * as modernWorkTimelineReducer from "../../../slice/modernWorkTimeline";
import { featureFilterList, filter } from "../../../slice/savedDataFilters";
import { ModernWorkFeature } from "../../../slice/modernWorkFeature";
import {
  formatCYDate,
  formatDate,
  formatDateTimeWithOutUtc,
} from "../../../utils/date";
import { isVoid } from "../../../utils/utils";

const ContentRow = (props: { feature: ModernWorkFeature }) => {
  return (
    <tr>
      <td>{props.feature.CCHFeatureId || ""}</td>
      <td>{props.feature.RoadmapId || ""}</td>
      <td>{props.feature.Product || ""}</td>
      <td>{props.feature.AllProducts || ""}</td>
      <td>{props.feature.ModernWOrkFeatureProductAreaName || ""}</td>
      <td>
        {props.feature.Tier === "1"
          ? "Tier 1"
          : props.feature.Tier === "2"
          ? "Tier 2"
          : props.feature.Tier === "3"
          ? "Tier 3"
          : props.feature.Tier === "Non-marketable"
          ? "Non-marketable"
          : ""}
      </td>
      <td>{props.feature.PublicTitle || ""}</td>
      <td>{props.feature.MarketingTitle || ""}</td>
      <td>{props.feature.MarketingTitleApproved ? "YES" : ""}</td>
      <td>{props.feature.Title || ""}</td>
      <td>{formatDate(props.feature.PlannedProductionDate)}</td>
      <td>{formatDate(props.feature.PublicDisclosureDate)}</td>
      <td>{formatDate(props.feature.PreviewDate)}</td>
      <td>{formatDate(props.feature.GADate)}</td>
      <td>{props.feature.ModernWorkSecurityEventCalendarMomentsTitle || ""}</td>
      <td>{props.feature.PMGContact || ""}</td>
      <td>{props.feature.PMContact || ""}</td>
      <td>
        {props.feature.MarketingDescription
          ? striptags(props.feature.MarketingDescription)
          : ""}
      </td>
      <td>{props.feature.MarketingDescriptionApproved ? "YES" : ""}</td>
      <td>
        {props.feature.Publicdescription
          ? striptags(props.feature.Publicdescription)
          : ""}
      </td>
      <td>{props.feature.FeatureTheme || ""}</td>
      <td>
        {props.feature.TriageTagName
          ? props.feature.TriageTagName.join(";")
          : ""}
      </td>
      <td>
        {props.feature.TriageDescription
          ? striptags(props.feature.TriageDescription)
          : ""}
      </td>
      <td>{props.feature.PublicRoadmapStatus || ""}</td>
      <td>{formatCYDate(props.feature.DatePublishedToPublicRoadmap)}</td>
      <td>{formatCYDate(props.feature.LastModifiedOnPublicRoadmap)}</td>
      <td>
        {props.feature.ServiceTargetDateDoD
          ? formatDate(props.feature.ServiceTargetDateDoD) === "01/01/1900"
            ? ""
            : formatDate(props.feature.ServiceTargetDateDoD)
          : ""}
      </td>
      <td>
        {props.feature.ServiceTargetDateGCC
          ? formatDate(props.feature.ServiceTargetDateGCC) === "01/01/1900"
            ? ""
            : formatDate(props.feature.ServiceTargetDateGCC)
          : ""}
      </td>
      <td>
        {props.feature.ServiceTargetDateGCCH
          ? formatDate(props.feature.ServiceTargetDateGCCH) === "01/01/1900"
            ? ""
            : formatDate(props.feature.ServiceTargetDateGCCH)
          : ""}
      </td>
      <td>{props.feature.CloudInstance || ""}</td>
      <td>{formatDate(props.feature.Created)}</td>
      <td>{props.feature.Id || ""}</td>
      <td>{props.feature.SourceADOID || ""}</td>
      <td>{props.feature.MessageCenterId || ""}</td>
      <td>{props.feature.FeatureLink || ""}</td>
      <td>
        {props.feature.CCHFeatureId === 0
          ? ""
          : "https://cch.azureedge.net/feature/" + props.feature.CCHFeatureId}
      </td>
      <td>{props.feature.ClientOrService || ""}</td>
      <td>{props.feature.MSETStags || ""}</td>
      <td>{props.feature.Status || ""}</td>
      <td>{props.feature.CustomerRings || ""}</td>
      <td>{props.feature.Platform || ""}</td>
      <td>{formatDateTimeWithOutUtc(props.feature.Modified)}</td>
      <td>{props.feature.ModifiedBy || ""}</td>
      <td>{props.feature.IsDelayed ? "Yes" : "" || ""}</td>
      <td>{props.feature.PreviewDelayed ? "Yes" : "" || ""}</td>
      <td>{props.feature.GADelayed ? "Yes" : "" || ""}</td>
    </tr>
  );
};

const Content = () => {
  const appDispatch = useAppDispatch();
  const dataSource = useSelector(
    (state: RootState) => state.modernWorkTimelineReducer
  );
  const [param] = useUrlQueryParam(
    featureFilterList.map((f: filter) => {
      return f.filterName;
    })
  );

  const isLoadingExportExcelFeature = useSelector(
    (state: RootState) =>
      state.modernWorkTimelineReducer.isLoadingExportExcelFeature
  );

  const isLoadedExportExcelFeature = useSelector(
    (state: RootState) =>
      state.modernWorkTimelineReducer.isLoadedExportExcelFeature
  );

  const isExportingExcelFeature = useSelector(
    (state: RootState) =>
      state.modernWorkTimelineReducer.isExportingExcelFeature
  );

  const featureDataSet = useMemo(() => {
    let datas: ModernWorkFeature[] = [];

    dataSource.featureExcelDownload.forEach(
      (feature: {
        productName: string;
        featureExcelDownload: ModernWorkFeature[];
      }) => {
        feature.featureExcelDownload?.forEach((feature: ModernWorkFeature) => {
          if (param.PMGContact) {
            if (decodeURIComponent(param.PMGContact) !== feature.PMGContact) {
              return true;
            }
          }

          if (param.PMContact) {
            if (decodeURIComponent(param.PMContact) !== feature.PMContact) {
              return true;
            }
          }

          if (param.Product) {
            if (param.Action === "And") {
              let decodeUrlProduct = decodeURIComponent(param.Product);
              if (feature.CCHFeatureId == 74387) {
                console.log("feature", feature);
              }
              let converToModenWorkfeature: ModernWorkFeature =
                feature as unknown as ModernWorkFeature;
              if (converToModenWorkfeature.AllProducts === undefined) {
                return true;
              } else {
                let products = decodeUrlProduct.split(";|;");
                let findProduct = true;
                products.forEach((p) => {
                  if (converToModenWorkfeature.AllProducts?.indexOf(p) === -1) {
                    findProduct = false;
                    return;
                  }
                });
                if (!findProduct) {
                  return;
                }
              }
            } else {
              if (
                decodeURIComponent(param.Product)
                  .split(";|;")
                  .filter((item) => item !== "")
                  .filter(
                    (item) =>
                      splitStringToArray(feature.Product).indexOf(item) !== -1
                  ).length === 0
              ) {
                return false;
              }
            }
          }

          if (
            param.ProductAreas &&
            feature.ModernWorkFeatureProductAreaId === null
          ) {
            return true;
          }

          if (
            param.ProductAreas &&
            feature.ModernWorkFeatureProductAreaId !== null
          ) {
            if (
              !decodeURIComponent(param.ProductAreas)
                .split(";|;")
                .filter((item) => item !== "")
                .includes(feature.ModernWorkFeatureProductAreaId.toString())
            ) {
              return true;
            }
          }

          if (param.RoadmapID) {
            if (decodeURIComponent(param.RoadmapID) !== feature.RoadmapId) {
              return true;
            }
          }

          if (param.SourceADOID) {
            if (decodeURIComponent(param.SourceADOID) !== feature.SourceADOID) {
              return true;
            }
          }

          if (param.CloudInstance) {
            if (
              !feature.CloudInstance ||
              feature.CloudInstance.trim().length == 0
            ) {
              if (
                decodeURIComponent(param.CloudInstance)
                  .split(";|;")
                  .filter((item) => item.toLowerCase() === "none").length === 0
              ) {
                return true;
              }
            } else {
              if (
                decodeURIComponent(param.CloudInstance)
                  .split(";|;")
                  .filter((item) => item.toLowerCase() !== "").length > 0
              ) {
                if (
                  decodeURIComponent(param.CloudInstance)
                    .split(";|;")
                    .filter((item) => item.toLowerCase() !== "")
                    .filter(
                      (item) =>
                        splitStringToArray(feature.CloudInstance).indexOf(
                          item
                        ) !== -1
                    ).length === 0
                ) {
                  return true;
                }
              } else {
                return true;
              }
            }
          }

          if (param.PublicRoadmapStatus) {
            if (
              decodeURIComponent(param.PublicRoadmapStatus)
                .split(";|;")
                .filter((item) => item !== "")
                .filter(
                  (item) =>
                    splitStringToArray(feature.PublicRoadmapStatus).indexOf(
                      item
                    ) !== -1
                ).length === 0
            ) {
              return true;
            }
          }

          if (param.TierId) {
            if (
              feature.TierId === undefined ||
              feature.TierId === null ||
              decodeURIComponent(param.TierId)
                .split(";|;")
                .filter((item) => item !== "")
                .indexOf(feature.TierId.toString()) === -1
            ) {
              return true;
            }
          }

          if (param.MomentId) {
            if (
              feature.ModernWorkSecurityEventCalendarMomentsId === undefined ||
              feature.ModernWorkSecurityEventCalendarMomentsId === null ||
              decodeURIComponent(param.MomentId)
                .split(";|;")
                .filter((item) => item !== "")
                .indexOf(
                  feature.ModernWorkSecurityEventCalendarMomentsId.toString()
                ) === -1
            ) {
              return true;
            }
          }

          if (param.TriageTagId) {
            if (
              feature.TriageTagIds === undefined ||
              feature.TriageTagIds === null ||
              feature.TriageTagIds.length === 0 ||
              decodeURIComponent(param.TriageTagId)
                .split(";|;")
                .filter((item) => item !== "")
                .filter(
                  (v) => feature?.TriageTagIds!.indexOf(parseInt(v)) !== -1
                ).length === 0
            ) {
              return true;
            }
          }

          if (param.Planning) {
            const Planning =
              feature.TierId !== null && feature.TierId !== undefined
                ? "4"
                : "5";
            if (
              decodeURIComponent(param.Planning)
                .split(";|;")
                .filter((item) => item !== "")
                .indexOf(Planning) === -1
            ) {
              return true;
            }
          }

          if (
            param.OnlyShowWithNewToRoadmap &&
            !isVoid(param.OnlyShowWithNewToRoadmap) &&
            param.OnlyShowWithNewToRoadmap.toLowerCase() === "true"
          ) {
            if (
              Math.abs(
                moment(new Date()).diff(moment(feature.Created)) /
                  1000 /
                  3600 /
                  24 /
                  30
              ) > 1
            ) {
              return true;
            }
          }

          datas.push(feature);
        });

        if (param.SortByDate) {
          const sortCondition = decodeURIComponent(param.SortByDate)
            .split(";|;")
            .filter((item) => item !== "");
          sortCondition.forEach((item) => {
            switch (item) {
              case "PlannedProductionDate":
                datas.sort((a, b) => {
                  const PlannedProductionDateA =
                    !a.PublicDisclosureDate ||
                    formatDate(a.PublicDisclosureDate) === "01/01/0001"
                      ? !a.PlannedProductionDate ||
                        formatDate(a.PlannedProductionDate) === "01/01/0001"
                        ? null
                        : formatDate(a.PlannedProductionDate)
                      : formatDate(a.PublicDisclosureDate);
                  const PlannedProductionDateB =
                    !b.PublicDisclosureDate ||
                    formatDate(b.PublicDisclosureDate) === "01/01/0001"
                      ? !b.PlannedProductionDate ||
                        formatDate(b.PlannedProductionDate) === "01/01/0001"
                        ? null
                        : formatDate(b.PlannedProductionDate)
                      : formatDate(b.PublicDisclosureDate);
                  if (
                    PlannedProductionDateA === null &&
                    PlannedProductionDateB !== null
                  ) {
                    return 1;
                  }
                  if (
                    PlannedProductionDateB === null &&
                    PlannedProductionDateA !== null
                  ) {
                    return -1;
                  }
                  if (
                    PlannedProductionDateB === null &&
                    PlannedProductionDateA === null
                  ) {
                    return 0;
                  }
                  return moment(PlannedProductionDateA) >
                    moment(PlannedProductionDateB)
                    ? -1
                    : moment(PlannedProductionDateA) <
                      moment(PlannedProductionDateB)
                    ? 1
                    : 0;
                });
                break;
              case "PreviewDate":
                datas.sort((a, b) => {
                  return moment(a.PreviewDate) > moment(b.PreviewDate)
                    ? -1
                    : moment(a.PreviewDate) < moment(b.PreviewDate)
                    ? 1
                    : 0;
                });
                break;
              case "GADate":
                datas.sort((a, b) => {
                  return moment(a.GADate) > moment(b.GADate)
                    ? -1
                    : moment(a.GADate) < moment(b.GADate)
                    ? 1
                    : 0;
                });
                break;
              case "DisclosureDate":
                datas.sort((a, b) => {
                  return moment(a.DisclosureDate) > moment(b.DisclosureDate)
                    ? -1
                    : moment(a.DisclosureDate) < moment(b.DisclosureDate)
                    ? 1
                    : 0;
                });
                break;
              case "DatePublishedToPublicRoadmap":
                datas.sort((a, b) => {
                  return moment(a.DatePublishedToPublicRoadmap) >
                    moment(b.DatePublishedToPublicRoadmap)
                    ? -1
                    : moment(a.DatePublishedToPublicRoadmap) <
                      moment(b.DatePublishedToPublicRoadmap)
                    ? 1
                    : 0;
                });
                break;
              default:
                break;
            }
          });
        }
      }
    );

    return datas;
  }, [dataSource, param]);

  useEffect(() => {
    if (isExportingExcelFeature) {
      if (
        isLoadingExportExcelFeature === false &&
        isLoadedExportExcelFeature === false
      ) {
        appDispatch(
          modernWorkTimelineReducer.actions.setIsLoadedExportExcelFeature(true)
        );
      }
    }
  }, [
    appDispatch,
    featureDataSet,
    isLoadingExportExcelFeature,
    isLoadedExportExcelFeature,
    isExportingExcelFeature,
  ]);
  return (
    <div className="d-none">
      <table id="timeline-table-excel-download">
        <tbody>
          <tr>
            <td>
              <strong>CCH ID</strong>
            </td>
            <td>
              <strong>Roadmap ID</strong>
            </td>
            <td>
              <strong>Product</strong>
            </td>
            <td>
              <strong>All Products</strong>
            </td>
            <td>
              <strong>Product Area</strong>
            </td>
            <td>
              <strong>Tier</strong>
            </td>
            <td>
              <strong>Public title</strong>
            </td>
            <td>
              <strong>Marketing Title</strong>
            </td>
            <td>
              <strong>Marketing Title Approved</strong>
            </td>
            <td>
              <strong>Engineering title</strong>
            </td>
            <td>
              <strong>Planned Production Date</strong>
            </td>
            <td>
              <strong>Public Disclosure Date</strong>
            </td>
            <td>
              <strong>Public preview date</strong>
            </td>
            <td>
              <strong>General available date</strong>
            </td>
            <td>
              <strong>Moment alignment</strong>
            </td>
            <td>
              <strong>PMM</strong>
            </td>
            <td>
              <strong>PM</strong>
            </td>
            <td>
              <strong>Marketing Description</strong>
            </td>
            <td>
              <strong>Marketing Description Approved</strong>
            </td>
            <td>
              <strong>Public description</strong>
            </td>
            <td>
              <strong>Feature Theme</strong>
            </td>
            <td>
              <strong>Marketing Tags</strong>
            </td>
            <td>
              <strong>Comments</strong>
            </td>
            <td>
              <strong>Publication status on public roadmap</strong>
            </td>
            <td>
              <strong>Date published to public roadmap</strong>
            </td>
            <td>
              <strong>Last modified on public roadmap</strong>
            </td>
            <td>
              <strong>Service Target Date DoD</strong>
            </td>
            <td>
              <strong>Service Target Date GCC</strong>
            </td>
            <td>
              <strong>Service Target Date GCC-H</strong>
            </td>
            <td>
              <strong>Cloud instance(s)</strong>
            </td>
            <td>
              <strong>Created</strong>
            </td>
            <td>
              <strong>Feature ID</strong>
            </td>
            <td>
              <strong>Source ADO ID</strong>
            </td>
            <td>
              <strong>Message Center ID</strong>
            </td>
            <td>
              <strong>Feature link</strong>
            </td>
            <td>
              <strong>CCH ID</strong>
            </td>
            <td>
              <strong>Client or service</strong>
            </td>
            <td>
              <strong>CCH tags</strong>
            </td>
            <td>
              <strong>Status</strong>
            </td>
            <td>
              <strong>Customer ring(s)</strong>
            </td>
            <td>
              <strong>Platform</strong>
            </td>
            <td>
              <strong>Last modified</strong>
            </td>
            <td>
              <strong>Last modified by</strong>
            </td>
            <td>
              <strong>Delayed</strong>
            </td>
            <td>
              <strong>Preview Delay</strong>
            </td>
            <td>
              <strong>GA Delay</strong>
            </td>
          </tr>
          {featureDataSet.map((feature) => {
            return <ContentRow feature={feature} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Content;
