import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { getFirstDayInTheMonth, getLastDayInTheMonth } from "../../utils/date";
import Header from "./Header";
import Filter from "./Filter";
import NavBar from "./NavBar";
import TimelineGrid from "./TimelineGrid";
import TimelineGridExcelDownload from "./TimelineGrid/timelineGridExcelDownload";
import SideBar from "../CopilotSidebar";
import {
  ContentShowMode,
  ModalType,
  TimelineMode,
  _AUTHORIZATIONTYPE,
} from "../Shared/constants";
import PowerBIComponent from "../PowerBIComponent";

export interface ModernWorkFeatureModalState {
  featureTitle: string;
  featureId: number;
  cchFeatureId: number;
  featureDisclosureDate: string | null;
  featurePublicDisclosureDate: string | null;
  deleteFeatureHistoryId: string;
  modalType: ModalType;
}

export const ModernWorkFeatureModalContext = React.createContext<{
  modalRef: React.RefObject<HTMLButtonElement> | null;
  modernWorkFeatureModalState: ModernWorkFeatureModalState;
  setModernWorkFeatureStateHandler: (
    value: ModernWorkFeatureModalState
  ) => void;
  deleteDialogRef: React.RefObject<HTMLButtonElement> | null;
  startDate: Date;
  appearStartDate: Date;
  appearEndDate: Date;
  setStartDate: (value: Date) => void;
  setAppearStartDate: (value: Date) => void;
  setAppearEndDate: (value: Date) => void;
  searchPhrase: string;
  setSearchPhrase: (value: string) => void;
  contentShowMode: ContentShowMode;
  setContentShowMode: (value: ContentShowMode) => void;
  timelineMode: TimelineMode;
  setTimelineMode: (value: TimelineMode) => void;
  searchHighlightPhrase: string;
  setSearchHighlightPhrase: (value: string) => void;
}>({
  modalRef: null,
  modernWorkFeatureModalState: {
    featureTitle: "",
    featureId: 0,
    cchFeatureId: 0,
    featureDisclosureDate: null,
    featurePublicDisclosureDate: null,
    deleteFeatureHistoryId: "",
    modalType: ModalType.New,
  },
  setModernWorkFeatureStateHandler: (value: ModernWorkFeatureModalState) => {},
  deleteDialogRef: null,
  startDate: new Date(),
  appearStartDate: new Date(),
  appearEndDate: new Date(),
  setStartDate: (value: Date) => {},
  setAppearStartDate: (value: Date) => {},
  setAppearEndDate: (value: Date) => {},
  searchPhrase: "",
  setSearchPhrase: (value: string) => {},
  contentShowMode: ContentShowMode.Grid,
  setContentShowMode: (value: ContentShowMode) => {},
  timelineMode: TimelineMode.Timeline,
  setTimelineMode: (value: TimelineMode) => {},
  searchHighlightPhrase: "",
  setSearchHighlightPhrase: (value: string) => {},
});
type ModernWorkTimelineProps = {
  showCopilotComponent: boolean;
  showPowerBIComponent: boolean;
  PowerBIconfig: any;
};
const ModernWorkTimeline: React.FC<ModernWorkTimelineProps> = ({
  showCopilotComponent,
  showPowerBIComponent,
  PowerBIconfig,
}) => {
  const featureDeleteDialogRef = useRef<HTMLButtonElement>(null);
  const featureModalRef = useRef<HTMLButtonElement>(null);
  const [modernWorkFeatureModalState, setModernWorkFeatureModalState] =
    useState({
      featureTitle: "",
      featureId: 0,
      cchFeatureId: 0,
      deleteFeatureHistoryId: "",
      modalType: ModalType.New,
    } as ModernWorkFeatureModalState);

  const [startDate, setStartDate] = useState(
    getFirstDayInTheMonth(moment(new Date()).format("MM/DD/YYYY"))
  );
  const [appearStartDate, setAppearStartDate] = useState(
    getFirstDayInTheMonth(moment(new Date()).format("MM/DD/YYYY"))
  );
  const [appearEndDate, setAppearEndDate] = useState(
    getLastDayInTheMonth(
      moment(new Date()).add(2, "months").format("MM/DD/YYYY")
    )
  );
  const [searchPhrase, setSearchPhrase] = useState("");
  const [timelineMode, setTimelineMode] = useState(TimelineMode.Timeline);
  const [contentShowMode, setContentShowMode] = useState(ContentShowMode.Grid);
  const [searchHighlightPhrase, setSearchHighlightPhrase] = useState("");
  const [isCopilotOpen, setIsCopilotOpen] = useState(false);

  const handleScroll = () => {
    let timelineNavbarStickyhead = document.querySelector(
      "#maincontent>.nav-container"
    );
    if (timelineNavbarStickyhead && timelineNavbarStickyhead !== null) {
      let top = timelineNavbarStickyhead.getBoundingClientRect().top;
      let left = timelineNavbarStickyhead.getBoundingClientRect().left;
      let stickyHead = document.querySelector(".container-navbar-stickyhead");
      if (stickyHead && stickyHead !== null) {
        if (top < 0) {
          if (stickyHead.className !== `container-navbar-stickyhead`) {
            stickyHead.className = `container-navbar-stickyhead`;
          }
        } else {
          if (stickyHead.className !== `container-navbar-stickyhead d-none`) {
            stickyHead.className = `container-navbar-stickyhead d-none`;
          }
        }
        if (stickyHead.getBoundingClientRect().left !== left) {
          (stickyHead as HTMLElement).style.left = left + "px";
        }
      }
    }
  };

  useEffect(() => {
    window.removeEventListener("scroll", handleScroll);
    window.addEventListener("scroll", handleScroll);
  }, []);

  useDocumentTitle("Modern Work Timeline - Cloud Marketing moments calendar");

  return (
    <ModernWorkFeatureModalContext.Provider
      value={{
        modalRef: featureModalRef,
        modernWorkFeatureModalState: modernWorkFeatureModalState,
        setModernWorkFeatureStateHandler: setModernWorkFeatureModalState,
        deleteDialogRef: featureDeleteDialogRef,
        startDate: startDate,
        setStartDate: setStartDate,
        appearStartDate: appearStartDate,
        appearEndDate: appearEndDate,
        setAppearStartDate: setAppearStartDate,
        setAppearEndDate: setAppearEndDate,
        searchPhrase: searchPhrase,
        setSearchPhrase: setSearchPhrase,
        contentShowMode: contentShowMode,
        setContentShowMode: setContentShowMode,
        timelineMode: timelineMode,
        setTimelineMode: setTimelineMode,
        searchHighlightPhrase: searchHighlightPhrase,
        setSearchHighlightPhrase: setSearchHighlightPhrase,
      }}
    >
      <Header />
      <Filter />
      <div className="container-navbar-stickyhead d-none">
        <NavBar
          isStickyHead={true}
          showPowerBIComponent={showPowerBIComponent}
        />
      </div>
      <NavBar showPowerBIComponent={showPowerBIComponent} />
      {contentShowMode === ContentShowMode.Dashboard ? (
        <PowerBIComponent workspaceid={3} config={PowerBIconfig} />
      ) : (
        <TimelineGrid />
      )}
      {showCopilotComponent && <SideBar workspaceid={3} />}
      <TimelineGridExcelDownload />
    </ModernWorkFeatureModalContext.Provider>
  );
};

export default ModernWorkTimeline;
