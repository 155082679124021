import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useUrlQueryParam } from "../../../utils/url";
import { isVoid, toExcel } from "../../../utils/utils";
import "../../Shared/css/timelineNavBar.scss";
import "./index.scss";
import powerbiicon from "./PowerBIIcon.png";
import copiloticon from "./CopilotIcon.png";
import {
  DirectionalHint,
  Dropdown,
  mergeStyleSets,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TooltipHost,
} from "@fluentui/react";
import {
  ContentShowMode,
  TimelineMode,
  TimelineType,
  _LOADINGSTATE,
} from "../../Shared/constants";
import { buttonStyles, dropdownStylesProps } from "../../Shared/styleProps";
import DatePickerWithCustomStyles from "../../Shared/FormInput/datepickerWithCustomStyles";
import { RootState, useAppDispatch } from "../../../store";
import * as modernWorkTimelineReducer from "../../../slice/modernWorkTimeline";
import * as calendarReducer from "../../../slice/calendar";
import { filter, featureFilterList } from "../../../slice/savedDataFilters";
import { ModernWorkFeatureModalContext } from "..";

const sortByDates: { dateId: string; dateTitle: string }[] = [
  { dateId: "PreviewDate", dateTitle: "Preview date" },
  { dateId: "GADate", dateTitle: "GA date" },
  {
    dateId: "DisclosureDate",
    dateTitle: "Public Disclosure Date",
  },
];
const styles = mergeStyleSets({
  root: {
    maxWidth: 300,
    selectors: {
      "> *": { marginBottom: 15 },
      "> table, th, td": { border: 0, backgroundColor: "white" },
    },
  },
  button: { marginRight: 10 },
  input: { width: 120 },
});

interface Props {
  isStickyHead?: boolean | undefined;
  showPowerBIComponent?: boolean | false;
  setIsCopilotOpen?: (isOpen: boolean) => void;
}

const FeatureNav = (props: Props) => {
  const [param, setParam] = useUrlQueryParam(
    featureFilterList.map((f: filter) => {
      return f.filterName;
    })
  );

  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  );

  const appDispatch = useAppDispatch();
  const customTrainDates = useSelector(
    (state: RootState) => state.calendarReducer.CustomTrainDates
  );
  const microsoftHoliday = useSelector(
    (state: RootState) => state.calendarReducer.MicrosoftHolidays
  );
  const moments = useSelector(
    (state: RootState) => state.calendarReducer.Moments
  );
  const azureUpdateDeckDueDates = useSelector(
    (state: RootState) => state.calendarReducer.AzureUpdatesDeckDueDates
  );
  const calendarDates = useSelector(
    (state: RootState) => state.calendarReducer.CalendarDates
  );

  useEffect(() => {
    if (props.isStickyHead === false || props.isStickyHead === undefined) {
      if (
        customTrainDates.length === 0 ||
        microsoftHoliday.length === 0 ||
        moments.length === 0 ||
        azureUpdateDeckDueDates.length === 0 ||
        calendarDates.length == 0
      ) {
        appDispatch(calendarReducer.getCalendarDates(TimelineType.MW));
      }
    }
  }, [appDispatch, props.isStickyHead]);

  const endMsg = isVoid(modernWorkFeatureModalContext.appearEndDate)
    ? "end date is required"
    : modernWorkFeatureModalContext.appearStartDate >
      modernWorkFeatureModalContext.appearEndDate
    ? "End date must be after Start date for proper display"
    : "";

  const isMonthLoading = useSelector(
    (state: RootState) => state.modernWorkTimelineReducer.isMonthLoading
  );

  const getData = () => {
    if (isMonthLoading === _LOADINGSTATE.pending) {
      return;
    }
    if (isVoid(modernWorkFeatureModalContext.appearStartDate)) {
      return;
    }
    if (isVoid(modernWorkFeatureModalContext.appearEndDate)) {
      return;
    }
    if (
      modernWorkFeatureModalContext.appearStartDate >
      modernWorkFeatureModalContext.appearEndDate
    ) {
      return;
    }
    if (modernWorkFeatureModalContext.timelineMode === TimelineMode.Search) {
      modernWorkFeatureModalContext.setContentShowMode(ContentShowMode.Grid);
      modernWorkFeatureModalContext.setSearchPhrase("");
      modernWorkFeatureModalContext.setTimelineMode(TimelineMode.Timeline);
      appDispatch(
        modernWorkTimelineReducer.initModernWorkTimelineDataSet(TimelineType.MW)
      ).then(() => {
        modernWorkFeatureModalContext.setStartDate(
          modernWorkFeatureModalContext.appearStartDate
        );
      });
    } else {
      modernWorkFeatureModalContext.setStartDate(
        modernWorkFeatureModalContext.appearStartDate
      );
    }
  };

  const isLoadingExportExcelFeature = useSelector(
    (state: RootState) =>
      state.modernWorkTimelineReducer.isLoadingExportExcelFeature
  );

  const isLoadedExportExcelFeature = useSelector(
    (state: RootState) =>
      state.modernWorkTimelineReducer.isLoadedExportExcelFeature
  );

  const isExportingExcelFeature = useSelector(
    (state: RootState) =>
      state.modernWorkTimelineReducer.isExportingExcelFeature
  );

  const handleDownload = () => {
    if (isExportingExcelFeature === true) {
      return false;
    }
    if (modernWorkFeatureModalContext.timelineMode == TimelineMode.Search) {
      appDispatch(
        modernWorkTimelineReducer.actions.setIsExportingExcelFeature(true)
      );

      if (isLoadedExportExcelFeature) {
        excelDownloadData();
      } else {
        appDispatch(
          modernWorkTimelineReducer.actions.setIsLoadingExportExcelFeature(true)
        );
        appDispatch(
          modernWorkTimelineReducer.getExcelDownloadSearchFeatureDataSet(
            modernWorkFeatureModalContext.searchPhrase
          )
        );
      }
    } else {
      if (isVoid(modernWorkFeatureModalContext.appearStartDate)) {
        return false;
      }
      if (isVoid(modernWorkFeatureModalContext.appearEndDate)) {
        return false;
      }
      if (
        modernWorkFeatureModalContext.appearStartDate >
        modernWorkFeatureModalContext.appearEndDate
      ) {
        return false;
      }
      appDispatch(
        modernWorkTimelineReducer.actions.setIsExportingExcelFeature(true)
      );

      if (isLoadedExportExcelFeature) {
        excelDownloadData();
      } else {
        appDispatch(
          modernWorkTimelineReducer.actions.setIsLoadingExportExcelFeature(true)
        );
        appDispatch(
          modernWorkTimelineReducer.getExcelDownloadFeatureDataSet(
            modernWorkFeatureModalContext.appearStartDate,
            modernWorkFeatureModalContext.appearEndDate
          )
        );
      }
    }
    return false;
  };

  const excelDownloadData = () => {
    const table =
      document.getElementById("timeline-table-excel-download")?.outerHTML || "";
    if (table !== "") {
      toExcel(`modernwork-timeline-excel`, table);
    }

    appDispatch(
      modernWorkTimelineReducer.actions.setIsExportingExcelFeature(false)
    );
  };

  useEffect(() => {
    if (
      (props.isStickyHead === false || props.isStickyHead === undefined) &&
      isExportingExcelFeature === true &&
      isLoadingExportExcelFeature === false &&
      isLoadedExportExcelFeature === true
    ) {
      excelDownloadData();
    }
  }, [
    isExportingExcelFeature,
    isLoadingExportExcelFeature,
    isLoadedExportExcelFeature,
  ]);

  useEffect(() => {
    appDispatch(
      modernWorkTimelineReducer.actions.setIsLoadedExportExcelFeature(false)
    );
  }, [
    modernWorkFeatureModalContext.appearStartDate,
    modernWorkFeatureModalContext.appearEndDate,
    modernWorkFeatureModalContext.timelineMode,
  ]);

  return (
    <div className="nav-container modernWorkTimeline-nav-container">
      <div className="row container-navbar">
        <div className="col-2 section-wrap">
          <div>
            <strong>Select a date to sort by</strong>
          </div>
          <Dropdown
            placeholder="Select one option"
            options={sortByDates.map(
              (item: { dateId: string; dateTitle: string }) => {
                return {
                  key: item.dateId,
                  text: item.dateTitle,
                };
              }
            )}
            styles={dropdownStylesProps}
            defaultSelectedKey={param.SortByDate}
            onChange={(event, item) => {
              if (item) {
                setParam({
                  ...param,
                  SortByDate: encodeURIComponent(item.key),
                });
              }
            }}
            ariaLabel="Select a date to sort by"
            calloutProps={{ doNotLayer: true }}
          />
        </div>
        <div className="col-3 d-flex section-wrap">
          <div className="w-40">
            <div>
              <strong>Start date</strong>
            </div>
            <div className="startDatePicker">
              <DatePickerWithCustomStyles
                value={modernWorkFeatureModalContext.appearStartDate}
                aria-role="tab"
                className={styles.input}
                placeholder="Select a date..."
                ariaLabel="Select a start date"
                onChangeHandler={(date: Date | null | undefined) => {
                  if (date && date != null) {
                    modernWorkFeatureModalContext.setAppearStartDate(date);
                  }
                }}
                timelineType={TimelineType.MW}
              />
            </div>
            <span className="date-error">
              {!isVoid(modernWorkFeatureModalContext.appearStartDate)
                ? ""
                : "begin date is required"}
            </span>
          </div>
          <div>
            <div>
              <label></label>
            </div>
            <div>&nbsp; - &nbsp;</div>
          </div>
          <div className="w-40">
            <div>
              <strong>End date</strong>
            </div>
            <div className="endDatePicker">
              <DatePickerWithCustomStyles
                value={modernWorkFeatureModalContext.appearEndDate}
                className={styles.input}
                aria-role="tab"
                placeholder="Select a date..."
                ariaLabel="Select a end date"
                onChangeHandler={(date: Date | null | undefined) => {
                  if (date && date != null) {
                    modernWorkFeatureModalContext.setAppearEndDate(date);
                  }
                }}
                timelineType={TimelineType.MW}
              />
            </div>
            <span
              style={{ fontSize: "13px", position: "absolute" }}
              className="date-error"
            >
              {endMsg}
            </span>
          </div>
          <div>
            <div>
              <label></label>
            </div>
            &nbsp;&nbsp;
            <PrimaryButton
              text="Go"
              allowDisabledFocus
              style={{ minWidth: "50px" }}
              onClick={getData}
              styles={buttonStyles}
            />
          </div>
        </div>
        <div className="col-1 section-wrap text-center export-to">
          <div>
            <strong>Export to:</strong>
          </div>
          <div>
            <a
              role="button"
              href="#!"
              aria-label="Excel, Export to Excel"
              onClick={handleDownload}
            >
              <span style={{ lineHeight: "32px" }}>Excel </span>
              {isExportingExcelFeature ? (
                <Spinner size={SpinnerSize.small} ariaLive="polite" />
              ) : null}
            </a>
          </div>
        </div>
        <div className="col section-wrap">
          <div>
            {" "}
            <div>
              <strong>Publication status on the public roadmap:</strong>
            </div>
          </div>
          <div
            className="feature-legends vertical-align mt-2"
            id="milistoneKey"
          >
            <div className="legend-item legend-item-published"></div>
            <div className="legend-item-title" title="Published">
              Published
            </div>
            <div className="legend-item legend-item-notpublished"></div>
            <div className="legend-item-title" title="Not published">
              Not published
            </div>
          </div>
        </div>
        <div className="col">
          <div>
            {" "}
            <div>
              <strong>Planning:</strong>
            </div>
          </div>
          <div
            className="dashboard-legend"
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              height: "50%",
              width: "100%",
              gap: "7px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <i
                className="fa fa-circle"
                aria-hidden="true"
                aria-label="No action"
              ></i>
              <span title="No action">No action</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <span>
                <i
                  className="ms-Icon root-43 "
                  data-icon-name="StatusTriangleOuter"
                  aria-hidden="true"
                  aria-label="Action needed"
                >
                  
                </i>
              </span>
              <span title="Action needed">Action needed</span>
            </div>
          </div>
        </div>
        <div
          className="col-1"
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: props.showPowerBIComponent ? "85px" : "15px",
          }}
        >
          {modernWorkFeatureModalContext.timelineMode ===
          TimelineMode.Search ? (
            ""
          ) : (
            <TooltipHost
              content="Grid Mode"
              styles={{ root: { display: "inline-block" } }}
              directionalHint={DirectionalHint.topCenter}
            >
              <i
                className="ms-Icon ms-Icon--GridViewMedium"
                style={
                  modernWorkFeatureModalContext.contentShowMode ===
                  ContentShowMode.Grid
                    ? {
                        borderStyle: "solid",
                        borderWidth: "medium",
                        cursor: "pointer",
                        fontSize: "35px",
                      }
                    : { cursor: "pointer", fontSize: "35px" }
                }
                onClick={() => {
                  modernWorkFeatureModalContext.setContentShowMode(
                    ContentShowMode.Grid
                  );
                }}
                alt-text="Grid Mode"
                aria-label="Grid Mode"
                role="button"
                aria-pressed={
                  modernWorkFeatureModalContext.contentShowMode ===
                  ContentShowMode.Grid
                }
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.currentTarget.click();
                  }
                }}
              ></i>
            </TooltipHost>
          )}
          <TooltipHost
            content="Column Mode"
            directionalHint={DirectionalHint.topCenter}
            styles={{ root: { display: "inline-block" } }}
          >
            <i
              className="ms-Icon ms-Icon--TripleColumn"
              style={
                modernWorkFeatureModalContext.contentShowMode ===
                ContentShowMode.Column
                  ? {
                      borderStyle: "solid",
                      borderWidth: "medium",
                      cursor: "pointer",
                      fontSize: "35px",
                      marginLeft: "12px",
                      transform: "rotate(90deg)",
                    }
                  : {
                      cursor: "pointer",
                      fontSize: "35px",
                      margin: "0 0 0 25px",
                      transform: "rotate(90deg)",
                    }
              }
              onClick={() => {
                modernWorkFeatureModalContext.setContentShowMode(
                  ContentShowMode.Column
                );
              }}
              alt-text="Column Mode"
              aria-label="Column Mode"
              role="button"
              aria-pressed={
                modernWorkFeatureModalContext.contentShowMode ===
                ContentShowMode.Column
              }
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.currentTarget.click();
                }
              }}
            ></i>
          </TooltipHost>
          {props.showPowerBIComponent && (
            <TooltipHost
              content="Dashboard Mode"
              directionalHint={DirectionalHint.topCenter}
              styles={{ root: { display: "inline-block" } }}
            >
              <img
                src={powerbiicon}
                style={
                  modernWorkFeatureModalContext.contentShowMode ===
                  ContentShowMode.Dashboard
                    ? {
                        fontSize: "35px",
                        cursor: "pointer",
                        borderStyle: "solid",
                        borderWidth: "medium",
                        marginLeft: "20px",
                        height: "35px",
                        width: "35px",
                      }
                    : {
                        fontSize: "35px",
                        cursor: "pointer",
                        marginLeft: "20px",
                        height: "35px",
                        width: "35px",
                        border: "solid",
                        color: "#D5D4D3",
                        borderWidth: "2px",
                        borderRadius: "6px",
                        padding: "2px",
                      }
                }
                onClick={() => {
                  modernWorkFeatureModalContext.setContentShowMode(
                    ContentShowMode.Dashboard
                  );
                }}
                alt-text="Dashboard Mode"
                aria-label="Dashboard Mode"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.currentTarget.click();
                  }
                }}
              />
            </TooltipHost>
          )}

          <TooltipHost
            content="Copilot Mode"
            directionalHint={DirectionalHint.topCenter}
            styles={{ root: { display: "inline-block" } }}
          >
            <img
              src={copiloticon}
              style={{
                fontSize: "35px",
                cursor: "pointer",
                marginLeft: "20px",
                height: "35px",
                width: "35px",
                border: "solid",
                color: "#D5D4D3",
                borderWidth: "2px",
                borderRadius: "6px",
                padding: "2px",
              }}
              onClick={() => {
                props.setIsCopilotOpen?.(true);
              }}
              alt-text="Copilot Mode"
              aria-label="Copilot Mode"
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.currentTarget.click();
                }
              }}
            />
          </TooltipHost>
        </div>
      </div>
    </div>
  );
};

export default FeatureNav;
