import { useRef, useState } from "react";
import {
  Callout,
  clamp,
  FocusTrapZone,
  IconButton,
  ITextFieldStyles,
  ITextField,
  Link,
  TextField,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import {
  useMsal,
  useAccount,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import "./index.scss";
import { isVoid } from "../../utils/utils";
import { useEffect } from "react";
import * as updatePMMDueDate from "../../slice/pmmDueDate";
import * as updatePMMDueDateService from "../../services/pmmDueDate";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const NavHeader = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const appDispatch = useAppDispatch();
  const [readyOnly, setreadyOnly] = useState(true);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const buttonId = useId("callout-button");
  const [dueDateFontSize, setdueDateFontSize] = useState("21px");
  const dateInfo = useSelector(
    (state: RootState) => state.pmmDueDateInfo.dateInfo
  );
  const isEditButtonUpdating = useSelector(
    (state: RootState) => state.pmmDueDateInfo.isUpdating
  );
  const location = useLocation().pathname.toLocaleLowerCase();

  const textFieldStyle: Partial<ITextFieldStyles> = {
    root: {
      width: 650,
      maxWidth: 800,
    },
    field: {
      maxHeight: 80,
      maxWidth: 900,
      minWidthL: 630,
      fontSize: dueDateFontSize,
      color: "inherit",
      width: 640,
    },
    fieldGroup: {
      maxWidth: 900,
      background: "transparent",
    },
  };
  const dueDateText = useRef<ITextField>(null);

  let userAccessType = localStorage.getItem("accessType");

  var currentUsername = account?.username.toLocaleLowerCase();
  if (
    currentUsername === "v-juweyand@microsoft.com" ||
    currentUsername === "jweyand@igglobal.com" ||
    currentUsername === "jweyand@infogain.com" ||
    currentUsername === "maemer@microsoft.com"
  ) {
    userAccessType = "ADMIN";
  }

  useEffect(() => {
    if (location === "/modernworktimeline") {
      updatePMMDueDateService.default.getPMMDueDate().then((data) => {
        appDispatch(updatePMMDueDate.actions.setDateInfo(data as string));
      });
    }
  }, [appDispatch, location]);

  return (
    <header className="nav-header text-white">
      <div className="d-flex justify-content-between">
        <div className="d-flex d-flex align-items-center">
          <div>
            <a href="/">
              <div className="microsoft-logo" title="Microsoft"></div>
            </a>
          </div>
          <div className="indicator"></div>
          <div style={{ display: "flex" }}>
            <h1 className="fw-bold" style={{ color: "#262626" }}>
              Cloud Marketing moments calendar
              {isVoid(process.env.REACT_APP_ENV) ||
              process.env.REACT_APP_ENV?.toLocaleLowerCase() === "production"
                ? ""
                : "  *** " + process.env.REACT_APP_ENV + " Site ***"}
              {!isVoid(process.env.REACT_APP_ENV) &&
              process.env.REACT_APP_ENV?.toLocaleLowerCase() === "uat" ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      "For user testing only! Please note: Changes made on this site will not be saved.",
                  }}
                />
              ) : null}
            </h1>
          </div>
        </div>
        {location === "/modernworktimeline" ? (
          <div
            style={{
              alignSelf: "flex-start",
              color: "red",
              display: "flex",
              flexDirection: "row",
              width: "670px",
              justifyContent: "flex-start",
            }}
          >
            <TextField
              componentRef={dueDateText}
              borderless={true}
              id="DueDateField"
              value={dateInfo}
              styles={textFieldStyle}
              readOnly={readyOnly}
              onChange={(e, newValue) => {
                if (newValue !== undefined) {
                  let contentLength = new TextEncoder().encode(newValue).length;

                  appDispatch(updatePMMDueDate.actions.setDateInfo(newValue));
                  if (contentLength > 60) {
                    setdueDateFontSize(
                      clamp((60 * 21) / newValue.length, 21, 18) + "px"
                    );
                  } else {
                    setdueDateFontSize("21px");
                  }
                }
              }}
            ></TextField>
            {userAccessType === "ADMIN" ? (
              <IconButton
                iconProps={{ iconName: !readyOnly ? "Completed" : "Edit" }}
                disabled={isEditButtonUpdating}
                onClick={async (event) => {
                  if (!readyOnly) {
                    appDispatch(
                      updatePMMDueDate.updatePMMDueDateInfo(dateInfo)
                    ).then(() => {
                      setreadyOnly(true);
                    });
                  } else {
                    if (dueDateText !== null && readyOnly) {
                      setreadyOnly(false);
                      dueDateText.current?.select();
                    }
                  }
                }}
                style={{ background: "transparent" }}
              ></IconButton>
            ) : null}
          </div>
        ) : null}

        <div>
          <Link
            id={buttonId}
            onClick={toggleIsCalloutVisible}
            style={{ color: "#000" }}
          >
            <div className="d-flex align-items-end">
              <AuthenticatedTemplate>
                <div className="userinfo">{account?.name}</div>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate></UnauthenticatedTemplate>
              <div className="userinfo-icon">
                <i className="fa fa-user-o" aria-hidden="true"></i>
              </div>
            </div>
          </Link>
          {isCalloutVisible && (
            <Callout
              style={{ width: 300, padding: "20px" }}
              target={`#${buttonId}`}
              onDismiss={toggleIsCalloutVisible}
              role="dialog"
              aria-live="assertive"
              setInitialFocus
            >
              <FocusTrapZone isClickableOutsideFocusTrap>
                <div
                  style={{ padding: "0px 0px 20px 0px" }}
                  className="d-flex justify-content-between"
                >
                  <div>Microsoft</div>
                  <div>
                    <Link
                      onClick={async (event) => {
                        const logoutRequest = {
                          account: account,
                          postLogoutRedirectUri:
                            "https://login.microsoftonline.com/common/oauth2/v2.0/logout",
                        };
                        localStorage.clear();
                        instance.logoutRedirect(logoutRequest);
                      }}
                    >
                      Sign out
                    </Link>
                  </div>
                </div>
                <div>
                  <div className="userinfo">{account?.name}</div>
                  <div className="userinfo">{account?.username}</div>
                  <a href="https://myaccount.microsoft.com/">View account</a>
                </div>
              </FocusTrapZone>
            </Callout>
          )}
        </div>
      </div>
    </header>
  );
};

export default NavHeader;
