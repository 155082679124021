/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from "react";
import iconImage from "../Assets/copilot.jpg";
import fullscreen from "../Assets/fullscreen.png";
import exitFullScreen from "../Assets/exitfullscreen.png";
import sendButtonImage from "../Assets/send-arrow.png";
import cleanButton from "../Assets/clean.png";
import pptButton from "../Assets/ppt.png";
import copyicon from "../Assets/copyicon.png";
import thumbsup from "../Assets/thumbsup.png";
import thumbsdown from "../Assets/thumbsdown.png";
import thumbsupclicked from "../Assets/thumbsupclicked.png";
import thumbsdownclicked from "../Assets/thumbsdownclicked.png";
import guideBook from "../Assets/guide.png";
import minimizeIcon from "../Assets/minimize.png";
import "./ChatContainer.scss";
import { TooltipHost } from "@fluentui/react";
import { ChatContainerProps, ChatMessage } from "./ChatContainerProps";

/**
 * ChatContainer is a React component that represents the chat container in the application.
 *
 */

const ChatContainer: React.FC<ChatContainerProps> = ({
  handleMinimizeClick,
  workspaceid,
  isVisible,
}) => {
  // State for storing the guide URL
  const [guideURL, setguideURL] = useState("");

  //Set the Copilot name based on the workspace
  const [copilot, setCopilot] = useState("");

  // State for toggling fullscreen mode
  const [isFullscreen, setIsFullscreen] = useState(false);

  // State for storing the backend URL
  const CopilotBackendURL = localStorage.getItem("CopilotBackendURL");

  // State for storing bot queries
  const [botQueries, setBotQueries] = useState<string[]>([]);

  // State for storing user questions
  const [userQuestions, setuserQuestions] = useState<string[]>([]);

  // State for storing the current input message
  const [inputMessage, setInputMessage] = useState("");

  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);

  // State for controlling the loading animation
  const [loading, setLoading] = useState(false);

  // State for controlling the show more/show less functionality
  const [showMoreStates, setShowMoreStates] = useState<boolean[]>([]);

  // State for controlling the visibility of images
  const [isImageVisible, setImageVisible] = useState(true);

  // State for controlling the visibility of the prompt guide dropdown
  const [isOpen, setIsOpen] = useState(false);

  // State for storing the array of suggested questions
  const [suggestedQuestions, setSuggestedQuestions] = useState<string[]>([]);

  // State for controlling whether to send the message
  const [shouldSendMessage, setShouldSendMessage] = useState(false);

  // Retrieve the user's name from local storage
  const user = localStorage.getItem("LoginAccountName");

  // Retrieving the first name from the user's name
  const firstName = user ? user.split(" ")[0] : "";

  // Retrieve the ai_session cookie from the document
  const aiSessionCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("ai_session="));

  // Extract the ai_session value from the cookie
  const aiSession = aiSessionCookie ? aiSessionCookie.split("=")[1] : undefined;

  // Retrieve the user's email from local storage
  const email = localStorage.getItem("LoginAccountEmail");

  // Reference for the suggested questions dropdown
  const suggestedQuestionsRef = useRef<HTMLDivElement>(null);

  // Reference for the user message
  const userMessage = useRef("");

  // Reference for the guide book icon
  const guideBookRef = useRef<HTMLImageElement>(null);

  // Initial state for upvote buttons
  const initialUpVoteStates = chatMessages.map(() => false);

  // Initial state for downvote buttons
  const initialDownVoteStates = chatMessages.map(() => false);

  // Inside your component function
  const [isChatboxFullscreen, setIsChatboxFullscreen] = useState(false); // State variable to track whether the chatbox is in fullscreen mode
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] =
    useState<boolean>(false);

  // new state variable to track the count of records
  const [displayedMessageCount, setDisplayedMessageCount] = useState(
    Array(chatMessages.length).fill(5)
  );

  // Define a state variable to store the feedback popup indices
  const [feedbackPopupIndices, setFeedbackPopupIndices] = useState<number[]>(
    []
  );

  //Handle upvote and downvote click by user
  const [upVoteClicked, setUpVoteClicked] =
    useState<boolean[]>(initialUpVoteStates);
  const [downVoteClicked, setDownVoteClicked] = useState<boolean[]>(
    initialDownVoteStates
  );
  const [feedbackPopupIndex, setFeedbackPopupIndex] = useState<number>(-1);
  const feedbackIndexRef = useRef<number>(-1); // Use a ref to store the index

  //Assigning the Copilot Name based on workspace
  const assignCopilot = (workspaceId: number) => {
    if (workspaceId === 1) {
      setCopilot("Azure");
    } else if (workspaceId === 2) {
      setCopilot("Security");
    } else {
      setCopilot("Modern Work");
    }
  };
  //Fetching the guide url from the server
  const fetchguideURL = async () => {
    try {
      const response = await fetch(`${CopilotBackendURL}/getguideurl`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      const data = await response.text();
      setguideURL(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Fetch the guide URL
  useEffect(() => {
    if (CopilotBackendURL !== "") {
      fetchguideURL();
    }
  }, [CopilotBackendURL]);

  // Fetch the suggested questions based on the workspace ID
  useEffect(() => {
    fetchData(workspaceid);
  }, [CopilotBackendURL, workspaceid]);

  // Assign the Copilot name based on the workspace ID
  useEffect(() => {
    assignCopilot(workspaceid);
  }, [workspaceid]);

  /**
   * Feedback mechanism function start
   */

  var isLike = 0;

  /**
   * Handles upvote click
   *
   */
  const handleUpVote = (index: number) => {
    if (!upVoteClicked[index]) {
      const newUpVoteClicked = [...upVoteClicked];
      newUpVoteClicked[index] = true;
      setUpVoteClicked(newUpVoteClicked);

      // If downvote was clicked, unclick it
      if (downVoteClicked[index]) {
        const newDownVoteClicked = [...downVoteClicked];
        newDownVoteClicked[index] = false;
        setDownVoteClicked(newDownVoteClicked);
      }
    }
    feedbackIndexRef.current = index; // Store the index in the ref
    isLike = 1;
    handleSubmitFeedback();
  };
  /**
   * Handles downvote click
   *
   */
  const handleDownVote = (index: number) => {
    if (!downVoteClicked[index]) {
      const newDownVoteClicked = [...downVoteClicked];
      newDownVoteClicked[index] = true;
      setDownVoteClicked(newDownVoteClicked);

      // If upvote was clicked, unclick it
      if (upVoteClicked[index]) {
        const newUpVoteClicked = [...upVoteClicked];
        newUpVoteClicked[index] = false;
        setUpVoteClicked(newUpVoteClicked);
      }
    }
    feedbackIndexRef.current = index; // Store the index in the ref

    // Open the feedback popup
    openFeedbackPopup(index);
  };
  /**
   * Opens the feedback popup
   *
   */
  const openFeedbackPopup = (index: number) => {
    setIsFeedbackPopupOpen(true);
    setFeedbackPopupIndex(index);
  };

  /**
   * Closes the feedback popup
   *
   */
  const closeFeedbackPopup = (index: number) => {
    setIsFeedbackPopupOpen(false);
    setFeedbackPopupIndex(index);
  };

  /**
   * Handles Enter key press in the feedback popup
   *
   */
  const handleFeedbackTextareaKeyDown = (
    e: React.KeyboardEvent<HTMLTextAreaElement>
  ): void => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmitFeedback();
    }
  };

  /**
   * Handles feedback submission
   */
  const handleSubmitFeedback = () => {
    const index: number = feedbackIndexRef.current; // Access the stored index from the ref

    let feedback: string = "User liked the response";
    if (isLike === 0) {
      const textFieldContentElement: HTMLTextAreaElement | null =
        document.getElementById(
          "TextFieldContent"
        ) as HTMLTextAreaElement | null;
      feedback = textFieldContentElement?.value || "";
    }

    // Get the chat history from session storage
    const storedChatHistory: string[] = JSON.parse(
      sessionStorage.getItem("Chat history") || "[]"
    );

    const feedbackData = {
      userMessage: userQuestions[(index - 1) / 2],
      userFeedback: feedback,
      botResponse: botQueries[(index - 1) / 2],
      chatHistory: storedChatHistory, // Include chat history in the feedback data
      username: user,
      useremail: email,
      userChoice: isLike,
      workspaceid: workspaceid,
    };

    // Make a POST request to the Flask API to store feedback
    fetch(`${CopilotBackendURL}/logfeedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(feedbackData),
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error submitting feedback:", error);
        // Handle error or show an error message
      });

    // After submitting the feedback, close the feedback popup
    setFeedbackPopupIndex(-1);
    setIsFeedbackPopupOpen(false);
  };

  /**
   * Updates the feedbackPopupIndices array when chatMessages change
   */
  useEffect(() => {
    // Update the feedbackPopupIndices array when chatMessages change
    const newIndices = Array.from({ length: chatMessages.length }, (_, i) => i);
    setFeedbackPopupIndices(newIndices);
  }, [chatMessages]);

  /**
   * Feedback mechanism function end
   */

  /**
   * Prompt guide function start
   */

  /**
   * Toggles the visibility of the guide book
   *
   */
  const guideBookShow = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    setIsOpen((prevState: boolean) => !prevState);
  };

  /**
   * Handles the event when a question is clicked
   *
   */
  const handleQuestionClick = (question: string): void => {
    // Set the inputMessage and the flag to send the message
    setInputMessage(question);
    setShouldSendMessage(true);
    setIsOpen(false);
  };

  /**
   * Prompt guide function end
   */

  /**
   * Fetches data based on workspace ID
   */
  const fetchData = async (workspaceid: number) => {
    try {
      // Generate a unique session storage key based on the workspace
      const sessionStorageKey = `SuggestedQuestions_${workspaceid}`;

      // Check if suggested questions are present in session storage
      const storedQuestions: string[] | null = JSON.parse(
        sessionStorage.getItem(sessionStorageKey) || "null"
      );

      if (storedQuestions) {
        // If questions are found in session storage, use them
        setSuggestedQuestions(storedQuestions);
      } else {
        // If questions are not found, make the API call
        const response = await fetch(
          `${CopilotBackendURL}/suggestedquestions`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify({
              workspace: workspaceid,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const fetchedQuestions = data.suggested_questions;

        // Update the state with fetched questions
        setSuggestedQuestions(fetchedQuestions);

        // Update the session storage with the fetched questions
        sessionStorage.setItem(
          sessionStorageKey,
          JSON.stringify(fetchedQuestions)
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  /**
   * Collapses the prompt guide when clicked outside the dropdown
   */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        suggestedQuestionsRef.current &&
        !suggestedQuestionsRef.current.contains(event.target as Node) &&
        guideBookRef.current !== event.target
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Before the component is unloaded (when the page is refreshed or closed), clear the chat history
    const handleBeforeUnload = () => {
      sessionStorage.removeItem("Chat history");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  /**
      Retrieves the existing chat history from session storage, or initializes it as an empty array if it doesn't exist
      */
  const chat_history: string[] = JSON.parse(
    sessionStorage.getItem("Chat history") || "[]"
  );

  /**
   * Handles "show more" button click
   *
   */
  const toggleShowMore = (message: { element: any[] }, index: number): void => {
    const newShowMoreStates = [...showMoreStates];
    newShowMoreStates[index] = !newShowMoreStates[index];
    setShowMoreStates(newShowMoreStates);

    if (Array.isArray(message.element)) {
      const newDisplayedMessageCount = [...displayedMessageCount];
      newDisplayedMessageCount[index] = newShowMoreStates[index]
        ? message.element.length - 1
        : 5;
      setDisplayedMessageCount(newDisplayedMessageCount);
    }
  };

  /**
   * Toggles full screen mode
   */
  const toggleFullscreen = () => {
    const newFullscreenState = !isFullscreen;
    setIsFullscreen(newFullscreenState);
    setImageVisible(!isImageVisible);

    // Set the state for chatbox fullscreen mode
    setIsChatboxFullscreen(newFullscreenState);
  };

  /**
   * Converts table data to HTML
   *
   * @param {string[][]} tableData - The table data to be converted to HTML
   * @returns {string} - The HTML representation of the table data
   */
  const tableToHtml = (tableData: string[][]): string => {
    let html: string = "<table>";
    // If workspaceid is 3, exclude the "Feature Id" column
    if (workspaceid === 3) {
      const featureIdIndex: number = tableData[0].indexOf("Feature Id");
      if (featureIdIndex !== -1) {
        tableData = tableData.map((row: string[]) => {
          return row.filter(
            (cell: string, index: number) => index !== featureIdIndex
          );
        });
      }
    }
    // Add headers
    html += "<thead><tr>";
    tableData[0].forEach((header: string) => {
      html += `<th>${header}</th>`;
    });
    html += "</tr></thead>";

    // Add body
    html += "<tbody>";
    tableData.slice(1).forEach((row: string[]) => {
      html += "<tr>";
      row.forEach((cell: string) => {
        html += `<td>${cell}</td>`;
      });
      html += "</tr>";
    });
    html += "</tbody>";

    html += "</table>";

    return html;
  };

  /**
   * Copies table data to clipboard in HTML format
   *
   */
  const copyToClipboard = (tableData: string[][], index: number): void => {
    const html: string = tableToHtml(tableData);
    navigator.clipboard.writeText(html);
  };

  /**
   * Exports data to a PPT
   *
   *
   */
  const exportPPT = (responseArray: any[], userQuestion: string): void => {
    if (!responseArray || responseArray.length === 0) {
      // Don't send an empty array
      return;
    }

    // Send the POST request to the specified endpoint with the array as the request body
    fetch(`${CopilotBackendURL}/exportToPPT`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
        Accept:
          "application/vnd.openxmlformats-officedocument.presentationml.presentation", // Set the expected response format
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({
        responses: responseArray, // Convert the array to a JSON string
        question: userQuestion, // Add the user's question
        workspaceid: workspaceid,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.blob(); // Handle the response as a binary blob
      })
      .then((data) => {
        // Create a temporary URL for the binary data
        const url = window.URL.createObjectURL(data);

        // Create a download link and trigger the download
        const a = document.createElement("a");
        a.href = url;
        if (workspaceid === 1) {
          a.download = "AzureTimelineData.pptx"; // Specify the download file name
        } else if (workspaceid === 2) {
          a.download = "SecurityTimelineData.pptx";
        } else {
          a.download = "ModernWorkTimelineData.pptx";
        }
        document.body.appendChild(a);
        a.click();

        // Clean up the temporary URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  };

  /**
   * Sends a user message to the server.
   * If the message is not empty, it is added to the chatMessages state.
   * A POST request is made to the server with the message and other related information.
   * The response from the server is processed and the chatMessages state is updated with the new messages.
   * If an error occurs during the fetch operation, a default bot response is added to the chatMessages state.
   */
  const sendMessage = (): void => {
    if (inputMessage.trim() === "") return;
    setShowMoreStates([]);

    // Add the user message to chatMessages
    setChatMessages([...chatMessages, { text: inputMessage, type: "user" }]);
    setInputMessage("");
    setLoading(true);

    // Storing the user question in the chat_history
    function appendQuestion(
      chat_history: any[],
      question: string,
      answer: string
    ): void {
      if (chat_history.length > 3) {
        chat_history.shift();
      }

      chat_history.push({
        inputs: {
          question: question,
          workspace: workspaceid,
        },
        outputs: {
          answer: answer,
          line_number: 0,
        },
      });

      // Update the session storage with the modified jsonData
      sessionStorage.setItem("Chat history", JSON.stringify(chat_history));
    }

    fetch(`${CopilotBackendURL}/api`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({
        question: inputMessage,
        chat_history: chat_history,
        loginAccountName: email,
        workspace: workspaceid,
        sessionid: aiSession,
      }),
    })
      .then((response) => response.json()) // Receive response as json
      .then((data) => {
        // Handle the response from the server
        setLoading(false);

        // Now you can access the "query" property and store it in `botquery`
        const botquery = data.query;

        setBotQueries((prevBotQueries: string[]) => [
          ...prevBotQueries,
          botquery,
        ]); // Add the new bot query to the array
        setuserQuestions((prevuserQuestions: string[]) => [
          ...prevuserQuestions,
          inputMessage,
        ]);

        // Process bot responses
        const botResponses = data.results;
        if (Array.isArray(botResponses)) {
          appendQuestion(chat_history, inputMessage, botquery);
        }
        const newMessages: ChatMessage[] = [
          { text: inputMessage, type: "user" },
        ]; // Copy existing messages

        // Checking if all cells are empty or not
        if (botResponses.length === 1) {
          var bot_no_record_responses = [
            "I couldn't find any records related to your question. Please feel free to ask another query related to marketing moments.",
            "No relevant records were found for your question. If you have any other inquiries about marketing moments, feel free to ask.",
            "Unfortunately, there are no records matching your current question. If you have any other queries regarding marketing moments, I'm here to help.",
          ];
          var bot_response = [...bot_no_record_responses].sort(
            () => 0.5 - Math.random()
          )[0];

          newMessages.push({
            element: bot_response, // Provide a default message for the bot response
            type: "bot",
            question: inputMessage,
          });
        } else {
          // Add the new questions to the array
          newMessages.push({
            element: botResponses, // Assign botResponses to the element property
            type: "bot",
            question: inputMessage,
          });
        }

        // Append new messages to the existing chatMessages state
        setChatMessages([...chatMessages, ...newMessages]);
      })
      .catch((error) => {
        console.error("Error sending message:", error);
        setLoading(false);
        // Add the user message to the userQuestions state
        setuserQuestions((prevuserQuestions: string[]) => [
          ...prevuserQuestions,
          inputMessage,
        ]);

        // Default message for bot in case of error
        var bot_error_responses = [
          "I'm sorry, I'm currently designed to provide information on the PMM workspace and marketing roadmap. Please try rephrasing your question for more relevant guidance.",
        ];
        var bot_response = [...bot_error_responses].sort(
          () => 0.5 - Math.random()
        )[0];

        setBotQueries((prevBotQueries: string[]) => [
          ...prevBotQueries,
          bot_response,
        ]);

        const newMessages: ChatMessage[] = [
          { text: inputMessage, type: "user" },
          { element: bot_response, type: "bot" },
        ];

        // Append new messages to the existing chatMessages state
        setChatMessages([...chatMessages, ...newMessages]);
      });
  };

  /**
   * A reference to the input textarea.
   *
   */
  const inputRef = useRef(null);

  /**
   * Handles the Enter key press event.
   * If the Enter key is pressed without the Shift key, it prevents the default action and calls the sendMessage function.
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  /**
   * Clears all messages from the chat, user input, and user questions.
   * It also resets the upvote and downvote states and removes the chat history from the session storage.
   */
  const clearMessages = () => {
    setChatMessages([]); //used for clearing container messages
    setInputMessage(""); //used for clearing user input messages
    setuserQuestions([]); //used for clearing user questions])
    setUpVoteClicked(initialUpVoteStates);
    setDownVoteClicked(initialDownVoteStates);
    sessionStorage.removeItem("Chat history");
  };

  /**
   * React effect hook that runs when the chatMessages state changes.
   * It scrolls the chat box to the bottom and sets the displayed message count based on the number of elements in each message.
   */
  useEffect(() => {
    const chatBox = document.querySelector(".chat-box-body");
    chatBox?.scrollTo(0, chatBox.scrollHeight); // Use optional chaining here
    const newDisplayedMessageCount = chatMessages.map((message) => {
      if (Array.isArray(message.element)) {
        return Math.min(5, message.element.length - 1);
      } else {
        return 5;
      }
    });
    setDisplayedMessageCount(newDisplayedMessageCount);
  }, [chatMessages]);

  /**
   * React effect hook that runs when the inputMessage, sendMessage, or shouldSendMessage changes.
   * If there is an input message and shouldSendMessage is true, it sends the message and resets the shouldSendMessage flag.
   */
  useEffect(() => {
    if (inputMessage !== "" && shouldSendMessage) {
      sendMessage();
      setShouldSendMessage(false); // Reset the flag after sending the message
    }
  }, [inputMessage, sendMessage, shouldSendMessage]);

  /**
   * Sets the welcome message based on the workspace ID.
   *
   */
  let welcomeMessage;
  if (workspaceid === 1) {
    welcomeMessage = `Hi  <b>${firstName}</b>, Welcome to the PMM Copilot in ${copilot} Workspace.  <br /><br />I'm here to assist
     you with marketing moments, products, and valuable insights. 
    <br /><br />
    You can ask me anything about marketing strategies, product features, and more. Not sure what to ask? Select a prompt from the guide to get started.<br/><br/>
    If you're new to Copilot, check out the <a href="//${guideURL}" target="_blank" style="color:rgb(0, 91, 162);">help deck</a>  for tips and guidance.`;
  } else if (workspaceid === 2) {
    welcomeMessage = `Hi <b> ${firstName} </b>, Welcome to the PMM Copilot in ${copilot} Workspace.  <br /><br />I'm here to assist
    you with marketing moments, products, and valuable insights. 
    <br /><br />
    You can ask me anything about marketing strategies, product features, and more. Not sure what to ask? Select a prompt from the guide to get started.<br/><br/>
    If you're new to Copilot, check out the <a href="//${guideURL}" target="_blank" style="color:rgb(0, 91, 162);">help deck</a>  for tips and guidance.`;
  } else if (workspaceid === 3) {
    welcomeMessage = `Hi <b> ${firstName} </b>, Welcome to the PMM Copilot in ${copilot} Workspace.  <br /><br />I'm here to assist
    you with marketing moments, products, and valuable insights. 
    <br /><br />
    You can ask me anything about marketing strategies, product features, and more. Not sure what to ask? Select a prompt from the guide to get started.<br/><br/>
    If you're new to Copilot, check out the <a href="//${guideURL}" target="_blank" style="color:rgb(0, 91, 162);">help deck</a>   for tips and guidance.`;
  }

  return (
    <>
      <div
        className={`copilot-overlay ${isVisible ? "" : "minimized"} ${
          isFullscreen ? "fullscreen" : ""
        }`}
      >
        <div
          className="chat-box-header"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img src={iconImage} alt="Icon" className="icon-image" />
          <div style={{ flexGrow: 1 }}>PMM Copilot</div>

          <TooltipHost content="Minimize" id="minimizeIconId">
            <img
              src={minimizeIcon}
              alt="Minimize"
              className="minimize-icon"
              onClick={handleMinimizeClick}
            />
          </TooltipHost>

          <TooltipHost
            content={isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
            id="fullscreenIconId"
          >
            <img
              src={isFullscreen ? exitFullScreen : fullscreen}
              alt="Icon"
              className="fullscreen-icon"
              onClick={toggleFullscreen}
            />
          </TooltipHost>
        </div>
        <p className="bottom-heading">
          Get AI-powered help to summarize content in {copilot} Workspace
        </p>

        <div className="chat-box-body">
          {isFeedbackPopupOpen && (
            <div
              className="overlay"
              onClick={(event) => closeFeedbackPopup}
            ></div>
          )}
          <div className="bot-response-box">
            <div className="bot-response">
              <p dangerouslySetInnerHTML={{ __html: welcomeMessage || "" }}></p>
            </div>
          </div>
          {chatMessages.map((message, index) => {
            let messageClass = "user-message_box";

            if (message.type === "user") {
              userMessage.current = message.text || "";
              messageClass = "user-message";
              return (
                <div className="user-message-box" key={index}>
                  <div className={messageClass}>
                    <p className="">{message.text}</p>
                  </div>
                </div>
              );
            } else if (message.type === "bot") {
              messageClass = "bot-response";
              if (Array.isArray(message.element)) {
                return (
                  <div className="bot-response-box" key={index}>
                    <div className={messageClass}>
                      {/* Convert the parsed data into an HTML table */}
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p className="results-count-text">{`${
                          displayedMessageCount[index]
                        } out of ${message.element.length - 1} results`}</p>

                        <img
                          className="copy-button"
                          src={copyicon}
                          alt=""
                          title="Copy to clipboard"
                          onClick={() => {
                            if (Array.isArray(message.element)) {
                              copyToClipboard(message.element, index);
                            }
                          }}
                        />

                        <p
                          className="export-ppt"
                          title="Export to PPT"
                          onClick={() => {
                            if (
                              Array.isArray(message.element) &&
                              message.question
                            ) {
                              exportPPT(message.element, message.question);
                            }
                          }}
                        >
                          <img
                            src={pptButton}
                            alt="PowerPoint"
                            className="ppt-image"
                            title="Export to PPT"
                          />{" "}
                          Export to PPT
                        </p>
                      </div>
                      <div className="table-container">
                        <table className="response-table">
                          <thead>
                            <tr>
                              {message.element[0]
                                .filter((header) => header !== "Feature Id")
                                .map((header, headerIndex) => (
                                  <th key={headerIndex}>{header}</th>
                                ))}
                            </tr>
                          </thead>
                          <tbody>
                            {message.element &&
                              message.element
                                .slice(1)
                                .map((row, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => {
                                      const featureIdIndex =
                                        message.element![0].indexOf(
                                          "Feature Id"
                                        );
                                      if (
                                        message.element![0][cellIndex] ===
                                        "Feature Id"
                                      ) {
                                        return null; // Don't render "Feature Id" cells
                                      } else if (
                                        workspaceid === 1 &&
                                        message.element![0][cellIndex] ===
                                          "Milestone ID"
                                      ) {
                                        const milestoneIdLink = `${window.location.origin}/AzureTimeline?milestoneId=${cell}`;
                                        return (
                                          <td key={cellIndex}>
                                            <a
                                              href={milestoneIdLink}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {cell}
                                            </a>
                                          </td>
                                        );
                                      } else if (
                                        workspaceid === 2 &&
                                        message.element![0][cellIndex] ===
                                          "Milestone ID"
                                      ) {
                                        const milestoneIdLink = `${window.location.origin}/SecurityTimeline?milestoneId=${cell}`;
                                        return (
                                          <td key={cellIndex}>
                                            <a
                                              href={milestoneIdLink}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {cell}
                                            </a>
                                          </td>
                                        );
                                      } else if (
                                        workspaceid === 2 &&
                                        message.element![0][cellIndex] ===
                                          "Messaging Material"
                                      ) {
                                        return (
                                          <td
                                            key={cellIndex}
                                            dangerouslySetInnerHTML={{
                                              __html: cell,
                                            }}
                                          />
                                        );
                                      } else if (
                                        workspaceid === 2 &&
                                        message.element![0][cellIndex] ===
                                          "CCH ID"
                                      ) {
                                        const publicRoadmapLink = `https://cch.azureedge.net/feature/${cell}`;
                                        return (
                                          <td key={cellIndex}>
                                            <a
                                              href={publicRoadmapLink}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {cell}
                                            </a>
                                          </td>
                                        );
                                      } else if (
                                        workspaceid === 3 &&
                                        message.element![0][cellIndex] ===
                                          "CCH Feature ID"
                                      ) {
                                        const featureId = row[featureIdIndex]; // Get the "Feature Id" from the same row
                                        const featureIdLink = `${
                                          window.location.origin
                                        }/ModernWorkTimeline?featureId=${parseInt(
                                          featureId,
                                          10
                                        )}`;
                                        return (
                                          <td key={cellIndex}>
                                            <a
                                              href={featureIdLink}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {cell}
                                            </a>
                                          </td>
                                        );
                                      } else if (
                                        workspaceid === 3 &&
                                        message.element![0][cellIndex] ===
                                          "Public Roadmap Link"
                                      ) {
                                        const publicRoadmapLink = `https://www.microsoft.com/en-us/microsoft-365/roadmap?rtc=1&filters=&searchterms=${cell}`;
                                        return (
                                          <td key={cellIndex}>
                                            <a
                                              href={publicRoadmapLink}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {cell}
                                            </a>
                                          </td>
                                        );
                                      } else if (
                                        workspaceid === 3 &&
                                        message.element![0][cellIndex] ===
                                          "Comments"
                                      ) {
                                        return (
                                          <td
                                            key={cellIndex}
                                            dangerouslySetInnerHTML={{
                                              __html: cell,
                                            }}
                                          />
                                        );
                                      } else if (
                                        message.element![0][cellIndex] ===
                                          "Original Value" ||
                                        message.element![0][cellIndex] ===
                                          "New Value"
                                      ) {
                                        return (
                                          <td
                                            key={cellIndex}
                                            dangerouslySetInnerHTML={{
                                              __html: cell,
                                            }}
                                          />
                                        );
                                      } else {
                                        return <td key={cellIndex}>{cell}</td>;
                                      }
                                    })}
                                  </tr>
                                ))
                                .slice(
                                  0,
                                  showMoreStates[index] ? undefined : 5
                                )}
                          </tbody>
                        </table>
                      </div>
                      <div>
                        {message.element.length > 6 && (
                          <p
                            className="show-more-button"
                            onClick={() => {
                              if (message.element) {
                                toggleShowMore(
                                  { element: message.element as any[] },
                                  index
                                );
                              }
                            }}
                          >
                            {showMoreStates[index] ? "Show less" : "Show more"}
                          </p>
                        )}
                      </div>
                      <div style={{ position: "relative", display: "flex" }}>
                        <img
                          className="thumbs-up"
                          src={
                            upVoteClicked[index] ? thumbsupclicked : thumbsup
                          }
                          alt=""
                          onClick={() => handleUpVote(index)}
                        />
                        <img
                          className="thumbs-down"
                          src={
                            downVoteClicked[index]
                              ? thumbsdownclicked
                              : thumbsdown
                          }
                          alt=""
                          onClick={() => handleDownVote(index)}
                        />
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="bot-response-box" key={index}>
                    <div className={messageClass}>
                      <p className="">{message.element}</p>
                      <div style={{ position: "relative", display: "flex" }}>
                        <img
                          className="thumbs-up"
                          src={
                            upVoteClicked[index] ? thumbsupclicked : thumbsup
                          }
                          alt=""
                          onClick={(event) => handleDownVote(index)}
                        />
                        <img
                          className="thumbs-down"
                          src={
                            downVoteClicked[index]
                              ? thumbsdownclicked
                              : thumbsdown
                          }
                          alt=""
                          onClick={(event) => handleDownVote(index)}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            }
            return (
              <div key={index} className={messageClass}>
                <p className="">{message.element}</p>
              </div>
            );
          })}

          {loading && (
            <div className="bot-response-box">
              <div className="bot-response loading">
                <p>Loading</p>
              </div>
            </div>
          )}
        </div>
        {feedbackPopupIndices.map((popupIndex) => (
          <div key={popupIndex}>
            {popupIndex === feedbackPopupIndex && (
              <div
                className={`feedback-popup ${
                  isChatboxFullscreen ? "fullscreen-mode-feedback" : ""
                }`}
              >
                <div className="header-content">
                  <div className="feedback-header">
                    <div
                      role="heading"
                      aria-level={1}
                      className="feedback-title"
                    >
                      Submit feedback
                    </div>
                  </div>
                  <div className="feedback-innerBody">
                    <div className="feedback-innerContent ">
                      <p className="feedback-titleContent">
                        Please tell us more about how we can improve this
                        response:
                      </p>
                      <div className="feedback-rootBody">
                        <div>
                          <div className="fieldGroup">
                            <textarea
                              id="TextFieldContent"
                              rows={5}
                              aria-invalid="false"
                              onKeyDown={handleFeedbackTextareaKeyDown}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Buttonactions">
                      <div className="ButtonactionsRight">
                        <span className="inneraction">
                          <button
                            type="button"
                            className="SubmitButton"
                            data-is-focusable="true"
                            onClick={() => handleSubmitFeedback()}
                          >
                            <span
                              className="flexContainer"
                              data-automationid="splitbuttonprimary"
                            >
                              <span className="extContainer">
                                <span className="SubmitButtonlabel">
                                  Submit
                                </span>
                              </span>
                            </span>
                          </button>
                        </span>
                        <span className="inneraction">
                          <button
                            type="button"
                            className="CloseButton"
                            data-is-focusable="true"
                            onClick={(event) => closeFeedbackPopup(-1)}
                          >
                            <span
                              className="flexContainer"
                              data-automationid="splitbuttonprimary"
                            >
                              <span className="textContainer">
                                <span className="CloseButtonlabel">Close</span>
                              </span>
                            </span>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
        <div
          className={`suggested-questions-container ${
            loading ? "suggested-questions-container--disabled" : ""
          }`}
          ref={suggestedQuestionsRef}
        >
          <div className="suggested-questions">
            {!loading && isOpen && (
              <div className="dropdown-list">
                <p className="guide-header">Prompt guide</p>
                {suggestedQuestions.map((question, index) => (
                  <div
                    key={index}
                    onClick={() => handleQuestionClick(question)}
                  >
                    {question}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div
          className={`chat-box-userinput ${
            loading ? "chat-box-userinput--disabled" : ""
          }`}
        >
          <div className="copilot-input-box">
            <textarea
              ref={inputRef}
              className={`textarea-input ${
                loading ? "chat-box-userinput--disabled" : ""
              }`}
              placeholder={`Ask me questions about ${copilot} Workspace...`}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={loading}
            />
          </div>

          <div className="image-container">
            <img
              src={cleanButton}
              title="Clear chat"
              alt="Clear chat"
              className={`clear-button ${
                loading ? "clear-button--disabled" : ""
              }`}
              onClick={loading ? undefined : clearMessages}
            />

            <img
              ref={guideBookRef}
              src={guideBook}
              title="Prompt guide"
              alt="GuideBook"
              className="guide-book"
              onClick={(event) => guideBookShow(event)}
            />

            <img
              src={sendButtonImage}
              alt="Send"
              className={`send-button ${
                loading ? "send-button--disabled" : ""
              }`}
              onClick={loading ? undefined : sendMessage}
            />
          </div>
        </div>
        <div className="footer-disclaimer">
          <p>
            Please note that responses may not always be accurate. We recommend
            reviewing the information for correctness. You can provide feedback
            using the thumbs up or thumbs down icons.
          </p>
        </div>
      </div>
    </>
  );
};

export default ChatContainer;
