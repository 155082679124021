import { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { KeyCodes, Link, Spinner, SpinnerSize } from "@fluentui/react";
import {
  convertMonthStringToDate,
  getFirstDayInTheMonth,
  getLastDayInTheMonth,
} from "../../utils/date";
import { isVoid } from "../../utils/utils";
import "./index.scss";
import "../Shared/css/modal.scss";
import {
  _AUTHORIZATIONTYPE,
  _LOADINGSTATE,
  ModalType,
  TimelineMode,
  TimelineType,
} from "../Shared/constants";
import Accordion from "../Shared/accordion";
import ModalOverlay from "../Shared/FormInput/modalOverlay";
import { RootState, useAppDispatch } from "../../store";
import * as modernWorkFeatureReducer from "../../slice/modernWorkFeature";
import * as modernWorkTimelineReducer from "../../slice/modernWorkTimeline";
import { getModernWorkSecurityEventCalendarMomentListByTimelineType } from "../../slice/modernWorkSecurityEventCalendarMoment";
import Details from "./details";
import DetailsFromMSETS from "./detailsFromMSETS";
import { DetailsHistory } from "./detailsHistory";
import { AppContext } from "../../App";
import { ModernWorkFeatureModalContext } from "../ModernWorkTimeline";
import { ModernWorkFeature } from "../../slice/modernWorkFeature";

const FeatureModal = () => {
  const appContext = useContext(AppContext);
  const [detailFormSubmited, setDetailFormSubmited] = useState(false);
  const [appearErrorInfo, setAppearErrorInfo] = useState(false);
  const modernWorkFeatureModalContext = useContext(
    ModernWorkFeatureModalContext
  );
  const { modernWorkSecurityEventCalendarMomentList, isMomentListLoading } =
    useSelector(
      (state: RootState) => state.modernWorkSecurityEventCalendarMomentReducer
    );
  const appDispatch = useAppDispatch();
  const dataSource = useSelector(
    (state: RootState) => state.modernWorkTimelineReducer.dataSet
  );

  useEffect(() => {
    if (
      modernWorkFeatureModalContext.modernWorkFeatureModalState.featureId > 0
    ) {
      appDispatch(
        modernWorkFeatureReducer.getFeature(
          modernWorkFeatureModalContext.modernWorkFeatureModalState.featureId
        )
      )
        .then(unwrapResult)
        .then((feature: ModernWorkFeature) => {
          if (
            modernWorkFeatureModalContext.modernWorkFeatureModalState
              .modalType === ModalType.Edit
          ) {
            modernWorkFeatureModalContext.setModernWorkFeatureStateHandler({
              featureTitle: !isVoid(feature.MarketingTitle)
                ? feature.MarketingTitle
                : !isVoid(feature.PublicTitle)
                ? feature.PublicTitle
                : !isVoid(feature.Title)
                ? feature.Title
                : "",
              featureId: feature.Id,
              cchFeatureId: feature.CCHFeatureId,
              featureDisclosureDate: feature.DisclosureDate,
              featurePublicDisclosureDate: feature.PublicDisclosureDate,
              deleteFeatureHistoryId: "",
              modalType: ModalType.Edit,
            });
          }
        })
        .catch(() => {
          modernWorkFeatureModalContext.modalRef?.current?.click();
        });
    }
  }, [modernWorkFeatureModalContext.modernWorkFeatureModalState.featureId]);

  const modernWorkFeature = useSelector<
    RootState,
    modernWorkFeatureReducer.ModernWorkFeature
  >((state) => state.modernWorkFeatureReducer.modernWorkFeature);

  useEffect(() => {
    if (isMomentListLoading === "") {
      appDispatch(
        getModernWorkSecurityEventCalendarMomentListByTimelineType({
          timelineType: TimelineType.MW,
        })
      );
    }
  }, [isMomentListLoading, modernWorkSecurityEventCalendarMomentList]);

  const readOnly =
    appContext.accessType !== _AUTHORIZATIONTYPE.admin &&
    appContext.accessType !== _AUTHORIZATIONTYPE.dri &&
    appContext.accessType !== _AUTHORIZATIONTYPE.editable;
  const [hideSpinner, setHideSpinner] = useState(false);
  const confirmationShow = useRef<HTMLButtonElement>(null);
  const [detailFormChanged, setDetailFormChanged] = useState(false);

  useEffect(() => {
    if (modernWorkFeature.__isLoading === _LOADINGSTATE.fullfilled) {
      setHideSpinner(true);
    }
  }, [modernWorkFeature.__isLoading]);

  return (
    <ModalOverlay
      title={`Manage CCH ID: ${
        modernWorkFeatureModalContext.modernWorkFeatureModalState.cchFeatureId
          ? modernWorkFeatureModalContext.modernWorkFeatureModalState
              .cchFeatureId
          : modernWorkFeature.CCHFeatureId
      } - ${
        !isVoid(
          modernWorkFeatureModalContext.modernWorkFeatureModalState.featureTitle
        )
          ? modernWorkFeatureModalContext.modernWorkFeatureModalState
              .featureTitle
          : !isVoid(modernWorkFeature.MarketingTitle)
          ? modernWorkFeature.MarketingTitle
          : !isVoid(modernWorkFeature.PublicTitle)
          ? modernWorkFeature.PublicTitle
          : modernWorkFeature.Title
      }`}
      refs={modernWorkFeatureModalContext.modalRef}
      formSubmited={true}
      formSubmitedCallback={() => {
        if (detailFormSubmited) {
          if (
            modernWorkFeatureModalContext.timelineMode != TimelineMode.Search
          ) {
            let dates: string[] = [];
            if (
              modernWorkFeature.PreviewDate !== "" &&
              moment(new Date(modernWorkFeature.PreviewDate))
                .utc()
                .format("MM/DD/YYYY") !== "01/01/1900"
            ) {
              dates.push(
                moment(new Date(modernWorkFeature.PreviewDate))
                  .utc()
                  .format("MM/01/YYYY")
              );
            }
            if (
              modernWorkFeature.GADate !== "" &&
              moment(new Date(modernWorkFeature.GADate))
                .utc()
                .format("MM/DD/YYYY") !== "01/01/1900"
            ) {
              dates.push(
                moment(new Date(modernWorkFeature.GADate))
                  .utc()
                  .format("MM/01/YYYY")
              );
            }
            if (
              modernWorkFeatureModalContext.modernWorkFeatureModalState
                .featureDisclosureDate &&
              modernWorkFeatureModalContext.modernWorkFeatureModalState
                .featureDisclosureDate !== null &&
              moment(
                new Date(
                  modernWorkFeatureModalContext.modernWorkFeatureModalState.featureDisclosureDate
                )
              )
                .utc()
                .format("MM/DD/YYYY") !== "01/01/1900"
            ) {
              dates.push(
                moment(
                  new Date(
                    modernWorkFeatureModalContext.modernWorkFeatureModalState.featureDisclosureDate
                  )
                )
                  .utc()
                  .format("MM/01/YYYY")
              );
            }
            if (
              modernWorkFeature.ModernWorkSecurityEventCalendarMomentsId !==
                null &&
              modernWorkSecurityEventCalendarMomentList.filter((f) => {
                return (
                  f.Id ===
                  modernWorkFeature.ModernWorkSecurityEventCalendarMomentsId
                );
              }).length > 0
            ) {
              let startDate = modernWorkSecurityEventCalendarMomentList.filter(
                (f) => {
                  return (
                    f.Id ===
                    modernWorkFeature.ModernWorkSecurityEventCalendarMomentsId
                  );
                }
              )[0].StartDate;
              dates.push(
                moment(new Date(startDate)).utc().format("MM/01/YYYY")
              );
            }
            if (
              modernWorkFeatureModalContext.modernWorkFeatureModalState
                .featurePublicDisclosureDate &&
              modernWorkFeatureModalContext.modernWorkFeatureModalState
                .featurePublicDisclosureDate !== null &&
              moment(
                new Date(
                  modernWorkFeatureModalContext.modernWorkFeatureModalState.featurePublicDisclosureDate
                )
              )
                .utc()
                .format("MM/DD/YYYY") !== "01/01/1900"
            ) {
              dates.push(
                moment(
                  new Date(
                    modernWorkFeatureModalContext.modernWorkFeatureModalState.featurePublicDisclosureDate
                  )
                )
                  .utc()
                  .format("MM/01/YYYY")
              );
            }
            if (
              modernWorkFeature.PublicDisclosureDate !== "" &&
              modernWorkFeature.PublicDisclosureDate !== null &&
              moment(new Date(modernWorkFeature.PublicDisclosureDate))
                .utc()
                .format("MM/DD/YYYY") !== "01/01/1900"
            ) {
              dates.push(
                moment(new Date(modernWorkFeature.PublicDisclosureDate))
                  .utc()
                  .format("MM/01/YYYY")
              );
            }
            if (
              modernWorkFeature.PlannedProductionDate !== "" &&
              modernWorkFeature.PlannedProductionDate !== null &&
              modernWorkFeature.PlannedProductionDate !== undefined &&
              moment(new Date(modernWorkFeature.PlannedProductionDate))
                .utc()
                .format("MM/DD/YYYY") !== "01/01/1900"
            ) {
              dates.push(
                moment(new Date(modernWorkFeature.PlannedProductionDate))
                  .utc()
                  .format("MM/01/YYYY")
              );
            }
            dates = Array.from(new Set(dates));
            dates.forEach((date: string) => {
              if (
                dataSource.filter((data) => {
                  return (
                    moment(convertMonthStringToDate(data.month))
                      .utc()
                      .format("MM/YYYY") ===
                    moment(date).utc().format("MM/YYYY")
                  );
                }).length > 0 &&
                dataSource.filter((data) => {
                  return (
                    moment(convertMonthStringToDate(data.month))
                      .utc()
                      .format("MM/YYYY") ===
                    moment(date).utc().format("MM/YYYY")
                  );
                })[0].isLoading !== ""
              ) {
                appDispatch(
                  modernWorkTimelineReducer.getFeatureDataSet(
                    getFirstDayInTheMonth(date),
                    getLastDayInTheMonth(date)
                  )
                );
              }
            });
          } else {
            appDispatch(
              modernWorkTimelineReducer.getSearchDataSet(
                modernWorkFeatureModalContext.searchPhrase
              )
            );
          }
          appDispatch(
            modernWorkTimelineReducer.actions.setIsLoadedExportExcelFeature(
              false
            )
          );
          modernWorkFeatureModalContext.setModernWorkFeatureStateHandler({
            featureTitle: "",
            featureId: 0,
            cchFeatureId: 0,
            featureDisclosureDate: null,
            featurePublicDisclosureDate: null,
            deleteFeatureHistoryId: "",
            modalType: ModalType.New,
          });
        }
      }}
      setFormSubmited={[
        setHideSpinner,
        setDetailFormSubmited,
        setDetailFormChanged,
        setAppearErrorInfo,
      ]}
      confirmationShow={confirmationShow}
      formChanged={
        detailFormChanged ||
        modernWorkFeatureModalContext.modernWorkFeatureModalState
          .deleteFeatureHistoryId != ""
      }
      formResetCallback={() => {
        appDispatch(modernWorkFeatureReducer.actions.reset());
        modernWorkFeatureModalContext.setModernWorkFeatureStateHandler({
          featureTitle: "",
          featureId: 0,
          cchFeatureId: 0,
          featureDisclosureDate: null,
          featurePublicDisclosureDate: null,
          deleteFeatureHistoryId: "",
          modalType: ModalType.New,
        });
      }}
    >
      {!hideSpinner ? (
        <Spinner
          label="Loading..."
          labelPosition="bottom"
          size={SpinnerSize.large}
        />
      ) : (
        ""
      )}
      <div
        className={!hideSpinner ? "d-none" : ""}
        onKeyDown={(e) => {
          if (e.keyCode == KeyCodes.escape) {
            document.getElementById("closeIconButton")?.click();
          }
        }}
      >
        <Accordion isDefaultOpen={true} title={"Internal marketing planning"}>
          <Details
            submited={detailFormSubmited}
            setSubmited={setDetailFormSubmited}
            appearErrorInfo={appearErrorInfo}
            setAppearErrorInfo={setAppearErrorInfo}
            confirmationShow={confirmationShow}
            detailFormChanged={detailFormChanged}
            setDetailFormChanged={setDetailFormChanged}
            disabled={readOnly}
          />
        </Accordion>
        <Accordion isDefaultOpen={true} title={"Marketing activities"}>
          <DetailsHistory
            appearErrorInfo={appearErrorInfo}
            disabled={readOnly}
            setDetailFormChanged={setDetailFormChanged}
          />
        </Accordion>
        <div className="row" style={{ marginTop: 18, marginBottom: 28 }}>
          <div className="col-12">
            <div className="disabled">
              <div>
                <span
                  style={{
                    font: "normal normal bold 18px/44px Segoe UI",
                    width: 253,
                    display: "inline-block",
                  }}
                >
                  Published to public roadmap:
                </span>
                {modernWorkFeature.PublicRoadmapStatus.toLowerCase() !==
                "published" ? (
                  "Not published"
                ) : (
                  <Link
                    href={
                      "https://www.microsoft.com/en-us/microsoft-365/roadmap?rtc=1&filters=&searchterms=" +
                      modernWorkFeature.RoadmapId
                    }
                    underline={true}
                    target="_blank"
                  >
                    {modernWorkFeature.RoadmapId.toString() +
                      ": " +
                      (!isVoid(modernWorkFeature.PublicTitle)
                        ? modernWorkFeature.PublicTitle
                        : modernWorkFeature.Title)}
                  </Link>
                )}
              </div>
              <div>
                To request a field advisory, fill out{" "}
                <a
                  href="https://aka.ms/thelaunchspaceproposal"
                  rel="noreferrer"
                  target="_blank"
                >
                  this form
                </a>{" "}
                and submit.
              </div>
            </div>
          </div>
        </div>
        <Accordion
          isDefaultOpen={true}
          title={"Details from CCH - for internal use"}
        >
          <DetailsFromMSETS />
        </Accordion>
      </div>
    </ModalOverlay>
  );
};

export default FeatureModal;
